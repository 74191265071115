<!--
 * @Author: wangding
 * @Date: 2020-10-09 11:01:08
 * @LastEditTime: 2020-12-17 15:43:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solaxcloud\src\components\common\baseTable.vue
-->
  
<!--
  用法：同传统的element-table一样用
  <baseTable  :data="data" border   stripe @selection-change="handleSelectionChange($event)" @change='change($event)' :column='column'  :select='true'>
     <template #mg_state="{data}">
       {{data.mg_state}}
     </template>
     <template #option="{data}">
       <div class="option-box">
         <el-button size='small' type="primary" icon="el-icon-edit" @click='editUsers(data)'></el-button>
         <el-button size='small' type="danger" icon="el-icon-delete" @click='deleteUsers(data)'></el-button>
         <el-button size='small' type="warning" icon="el-icon-setting" @click='editRoles(data)'></el-button>
       </div>
     </template>
   </baseTable>
-->
<template>
  <div class="baseTable-main-box">
    <el-table max-height="500"
        v-bind='$attrs' @selection-change="handleSelection" :fit='true' :border='false'>
      <el-table-column type="expand" v-if='$attrs.expand'>
        <template slot-scope="props">
          <slot  name='expand' :data='props.row'>{{props.row}}</slot>
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        width="55" align='center' v-if='$attrs.select'>
      </el-table-column>
      <el-table-column type="index" :index="indexMethod" :label="$t('pageNumber')" align='center' width="70">
      </el-table-column>
      <!-- <el-table-column type="index" label="#" align='center'>
      </el-table-column> -->

      <template v-for='(item,index) in $attrs.column' >
        <template v-if="item.show!==false"> 
        <el-table-column
        :prop="item.value"
        :label="item.title"
        :min-width="item.min_width||''"
        :key='index'
        v-if='item.slot'
        :sortable='item.sortable?true:false'
        align='center'
        
        >
          <template slot-scope="scope">
            <div class="break-word-text">
              <slot  :name='item.slot' :data='scope.row'>
                <div class="break-word-text" :title='isNot(scope.row[item.value])'> 
                  {{isNot(scope.row[item.value])}}
                </div>
              </slot>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :prop="item.value"
          :label="item.title"
          :min-width="item.min_width||''"
          :key='index'
          v-else
          :sortable='item.sortable?true:false'
          align='center'
          >
          <template slot-scope="scope">
            <div class="break-word-text" :title='isNot(scope.row[item.value])'> 
              {{isNot(scope.row[item.value])}}
            </div>
          </template>
        </el-table-column>
        </template>
      </template>
    </el-table>
  </div>
</template>
<script>
import 'element-ui'
export default {
  data () {
    return {
      data: []
    }
  },
  props: {
  },
  mounted () {
    let that = this
    document.onkeydown = function(e){
        if(e.keyCode == 13 && that.$parent.query ){
          that.$parent.query()
        }
    }
  },
  methods: {
    indexMethod(index){
      let current=this.$parent.current;
      let size=this.$parent.size;
      if(current){
        if(current==1){
          return index+1; 
        }else{
          return index+1+size*(current-1);
        }
      }else{
        return index+1;
      }
    },
    handleSelection (val) {
      if (Object.keys(this.$listeners).indexOf('selection-change') !== -1) {
        this.$emit('selection-change', val)
      }
    },
    isNot(val){
      if(val === null || val === "" || val === undefined){
        return ' - '
      }else{
        return val
      }
    }
  }
}
</script>
<style lang="less">
.baseTable-main-box{
  .break-word-text{
    // white-space: nowrap;
    overflow: visible;
  }
  .el-table td, .el-table th {
      padding: 0;
      }
  .el-table tr {
      background-color: #e4e4e4;
  }
  .el-table th {
      background-color: #666;
      padding: 8px 0px;
      width: 100px;
  }
  .el-table__footer-wrapper thead div, .el-table__header-wrapper thead div {
      background-color: #666;
      color: #fff;
  }
  .break-word-text{
    word-wrap:break-word;
  }
  .el-table th>.cell{
    overflow: visible;
    white-space:nowrap;
  }
  // .el-table .cell {
  //   overflow: visible;
  // }
}
</style>
