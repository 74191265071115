<!--
 * @Author: wangding
 * @Date: 2020-11-09 17:54:23
 * @LastEditTime: 2020-11-10 11:58:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solaxcloud\src\components\common\baseFileInput.vue
    用法：<baseFile v-model='form.file'></baseFile>，
    直接引用，v-model绑定，接口类型换成已经封装好的POST3
    参照 ：\solaxcloud\src\views\device\sn\import.vue
-->
<template>
    <div class="base-file-input-main-box">
        <el-input  :placeholder="$t('system.choose.file')" v-model='name'></el-input>
        <input type="file" class="file-old" ref='oldFile' @change='selsctFile'>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            name:''
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
    },
    methods: {
        selsctFile(){
            this.name = this.$refs.oldFile.files[0].name
            let formData = new FormData();
            let file = this.$refs.oldFile
            formData.append('file', file.files[0]);
            this.$emit('input',formData.get('file'))
        }
    },
};
</script>

<style  lang="less" scoped>
.base-file-input-main-box{
    width: 100%;
    position: relative;
    .file-old{
        width: 80%;
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        opacity: 0;
    }
}
</style>
